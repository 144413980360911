<script lang="ts">
	import type { iFrame } from "../interfaces/iFrame.js";
	import FrameView from "../components/FrameView.svelte";
	export let width: number;
	export let height: number;
	export let views: iFrame[];

	let rows: number[];
	let columns: number[];

	$: if (width) {
		rows = fillArray(height);
	}

	$: if (height) {
		columns = fillArray(width);
	}

	function fillArray(count: number) {
		const array = new Array<number>();
		for (let i = 0; i < count; i++) {
			array[i] = i;
		}
		return array;
	}
</script>

<div
	class="grid"
	style:grid-template-columns={columns.map(() => "1fr").join(" ")}
	style:grid-template-rows={rows.map(() => "1fr").join(" ")}
>
	{#each views as frame, index}
		<FrameView {frame} title={`${index + 1}`} />
	{/each}
</div>

<style>
	.grid {
		display: grid;
		gap: 0;
		height: 100vh;
		width: 100vw;
	}
</style>
