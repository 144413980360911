<script lang="ts">
	import { onDestroy, onMount, tick } from "svelte";
	import { parseCronExpression } from "cron-schedule";
	import { TimerBasedCronScheduler } from "cron-schedule/schedulers/timer-based.js";
	import type { iFrame } from "../interfaces/iFrame.js";
	import UrlFrameView from "./url/URLFrameView.svelte";
	import VideoFrameView from "./video/VideoFrameView.svelte";
	import { CRON_REGEX } from "../helpers/cron.js";
	import { timeout } from "../helpers/timeout.js";

	export let frame: iFrame;
	export let title = "Frame";
	export let block_interaction = false;
	export let enabled = true;
	let css_color = frame.css_color;
	$: css_color = frame.css_color ?? "#000000";

	let dom_view: HTMLDivElement;
	let width: number,
		height: number,
		view_width: number,
		view_height: number,
		translate_x: number = 0,
		translate_y: number = 0;
	let is_reloading = false;

	let current_schedule: ReturnType<typeof TimerBasedCronScheduler["setInterval"]> | undefined =
		undefined;

	$: applyRotation(frame, view_width, view_height);
	$: updateRefreshCron(frame);

	function applyRotation(frame: iFrame, view_width: number, view_height: number) {
		switch (frame.rotation) {
			case 270:
				translate_x = 0;
				translate_y = view_height;
				width = view_height;
				height = view_width;
				break;
			case 180:
				translate_x = view_width;
				translate_y = view_height;
				width = view_width;
				height = view_height;
				break;
			case 90:
				translate_x = view_width;
				translate_y = 0;
				width = view_height;
				height = view_width;
				break;
			default:
				translate_x = 0;
				translate_y = 0;
				width = view_width;
				height = view_height;
				break;
		}
	}

	function updateRefreshCron(frame: iFrame) {
		if (frame.refresh_cron) {
			if (!frame.refresh_cron.match(CRON_REGEX)) {
				console.error("Frame", title, "Cron", frame.refresh_cron, "failed to validate");
				return cancelCron();
			}
			cancelCron();
			try {
				const cron = parseCronExpression(frame.refresh_cron);
				current_schedule = TimerBasedCronScheduler.setTimeout(cron, () => reload(), {
					errorHandler: (error) => {
						console.error("Error during cron job", error, "Not restarting.");
					},
				});
			} catch (error) {
				console.error("Failed to set up refresh cron", error);
			}
		} else {
			return cancelCron();
		}
	}

	function cancelCron() {
		if (current_schedule) {
			try {
				TimerBasedCronScheduler.clearTimeoutOrInterval(current_schedule);
			} catch (error) {
				console.error("Failed to cancel Cron", error);
			} finally {
				current_schedule = undefined;
			}
		}
	}

	async function reload() {
		if (is_reloading) return;
		is_reloading = true;
		enabled = false;
		await tick();
		enabled = true;
		is_reloading = false;
		console.debug("Frame", title, "has been reloaded");
	}

	onMount(() => {
		const observer = new ResizeObserver(([view]) => {
			view_width = view.contentRect.width;
			view_height = view.contentRect.height;
		});
		observer.observe(dom_view);
		return () => {
			observer.disconnect();
		};
	});

	onDestroy(() => cancelCron());
</script>

<div class="view" bind:this={dom_view}>
	<div
		class="contents"
		style:transform="translateX({translate_x}px) translateY({translate_y}px) rotate({frame.rotation ??
			0}deg) "
	>
		{#if block_interaction}
			<div class="overlay" />
		{/if}
		{#if enabled}
			{#if "url" in frame}
				<UrlFrameView {frame} {title} {block_interaction} {width} {height} on:reload={reload} />
			{:else if "video_src" in frame}
				<VideoFrameView {frame} {title} {block_interaction} />
			{:else}
				<div class="frame" style:background-color={css_color} {title}>{title}</div>
			{/if}
		{:else}
			<div class="frame" style:background-color={css_color} {title}>{title}</div>
		{/if}
	</div>
</div>

<style>
	.frame {
		display: flex;
		max-width: 100%;
		max-height: 100%;
		border: none;
		background-color: magenta;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		font-weight: bolder;
		font-size: larger;
		box-sizing: border-box;
	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
	}
	.view {
		position: relative;
		overflow: hidden;
	}
	.contents {
		transform-origin: top left;
	}
</style>
