export interface BaseFrame {
	/** @default "#000000" */
	css_color?: string;
	/**
	 * @default 0
	 */
	rotation?: 0 | 90 | 180 | 270;
	/**
	 * Cron job string. Disabled if unset or empty string.
	 * @default undefined
	 */
	refresh_cron?: string;
}

export interface URLFrame extends BaseFrame {
	url: string;
	/**
	 * Refresh in 1 minute if the page fails to provide a 200 "OK" response. This does not work if sites deny CORS.
	 * @default undefined
	 */
	check_200?: boolean;
}

export interface VideoFrame extends BaseFrame {
	video_src: string;
}

export type iFrame = VideoFrame | URLFrame;

export enum FrameType {
	URLFrame,
	VideoFrame,
}
