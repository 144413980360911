import type { iConfig } from "../interfaces/iConfig.js";
import type { iFrame } from "../interfaces/iFrame.js";

export const CONFIG_QUERY = "cfg";
export const FRAMES_QUERY = "frames";

export function parseSearchQueryOptions(params: URLSearchParams) {
	const config_str = params.get(CONFIG_QUERY);
	if (!config_str) return;
	let config: iConfig;
	try {
		config = JSON.parse(config_str);
	} catch (error) {
		console.error("Failed to parse search param", CONFIG_QUERY);
		return;
	}
	const frames_str = params.get(FRAMES_QUERY);
	if (!frames_str) return;
	let frames: iFrame[];
	try {
		frames = JSON.parse(frames_str);
	} catch (error) {
		console.error("Failed to parse search param", FRAMES_QUERY);
		return;
	}
	return { config, frames };
}

export function saveSearchQueryOptions({ config, frames }: { config: iConfig; frames: iFrame[] }) {
	return new URLSearchParams({
		[CONFIG_QUERY]: JSON.stringify(config),
		[FRAMES_QUERY]: JSON.stringify(frames),
	});
}
