<script lang="ts">
	import type { VideoFrame } from "../../interfaces/iFrame.js";

	export let frame: VideoFrame;
	export let block_interaction = false;
	export let title = "Frame";
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<iframe
	class="frame"
	referrerpolicy="no-referrer"
	{title}
	src={frame.video_src.toString()}
	style:background-color={frame.css_color}
	on:click|preventDefault={() => false}
	style:pointer-events={block_interaction ? "none" : "unset"}
/>
<!-- svelte-ignore a11y-media-has-caption -->
<video
	src={frame.video_src}
	style:background-color={frame.css_color}
	controls={!block_interaction}
	loop={true}
	autoplay={true}
	muted={true}
/>
