<script lang="ts">
	import type { iConfig } from "./lib/interfaces/iConfig.js";
	import type { iFrame } from "./lib/interfaces/iFrame.js";
	import MultiView from "./lib/views/MultiView.svelte";
	import { onMount } from "svelte";
	import ConfigEditor from "./lib/views/ConfigEditor.svelte";
	import { parseSearchQueryOptions, saveSearchQueryOptions } from "./lib/helpers/load-config.js";
	let config: iConfig | undefined = undefined;
	let frames: iFrame[] = [];
	let previous_config: iConfig | undefined = { columns: 1, rows: 1 };
	let previous_frames: iFrame[] = [];

	onMount(() => {
		// config = sportspulse_config;
		// frames = sportspulse_frames;
		const loaded = parseSearchQueryOptions(new URLSearchParams(window.location.search));
		if (loaded) {
			config = loaded.config;
			frames = loaded.frames;
		}
	});

	onMount(() => {
		(window as any).started = true;
		if ((window as any).failsafeTimer) {
			clearTimeout((window as any).failsafeTimer);
			(window as any).failsafeTimer = undefined;
			console.debug("Cleared failsafe timer");
		}
	});

	function applySearchParams(params: URLSearchParams) {
		const url = new URL(window.location.toString());
		url.search = "";
		url.search = params.toString();
		// for (const [key, value] of params.entries()) {
		// 	url.searchParams.set(key, value);
		// }
		history.pushState({}, "", url);
	}
</script>

<svelte:head>
	<title>MultiView</title>
</svelte:head>

<svelte:window
	on:keydown={(event) => {
		if (event.key === "Escape" && config && frames) {
			previous_config = config;
			previous_frames = frames;
			config = undefined;
			frames = [];
			applySearchParams(new URLSearchParams());
		}
	}}
/>

{#if config && frames}
	<MultiView width={config.columns} height={config.rows} views={frames} />
{:else}
	<ConfigEditor
		on:save={({ detail }) => {
			const options = saveSearchQueryOptions(detail);
			applySearchParams(options);
			config = detail.config;
			frames = detail.frames;
		}}
		bind:config={previous_config}
		bind:frames={previous_frames}
	/>
{/if}
