<script lang="ts">
	import FrameEditor from "../components/FrameEditor.svelte";
	import type { iConfig } from "../interfaces/iConfig.js";
	import type { iFrame } from "../interfaces/iFrame.js";
	import { createEventDispatcher } from "svelte";
	const dispatch = createEventDispatcher();

	export let config: iConfig = { columns: 1, rows: 1 };
	export let frames: iFrame[] = [{ css_color: "#FF00FF", url: "" }];

	let columns: string = "";
	let rows: string = "";

	$: config.columns = Math.min(Math.max(config.columns, 1), 9);
	$: config.rows = Math.min(Math.max(config.rows, 1), 9);

	$: if (config.columns) {
		balanceFramesArray();
		columns = makeGridString(config.columns);
	}

	$: if (config.rows) {
		balanceFramesArray();
		rows = makeGridString(config.rows);
	}

	function makeGridString(count: number) {
		const array = new Array<string>();
		for (let i = 0; i < count; i++) {
			array.push(`${(1 / count) * 100}%`);
		}
		return array.join(" ");
	}

	function balanceFramesArray() {
		const length = config.columns * config.rows;
		if (length < frames.length) {
			frames.length = length;
			frames = frames;
		} else if (length > frames.length) {
			for (let index = frames.length - 1; index < length; index++) {
				frames.push(
					JSON.parse(
						JSON.stringify(frames[frames.length - 1] ?? { css_color: "#FF00FF", url: "" }),
					),
				);
			}
			frames = frames;
		}
	}

	function saveConfig() {
		dispatch("save", { config, frames });
	}
</script>

<div class="config">
	<label>Columns <input type="number" bind:value={config.columns} step="1" min="1" max="9" /></label
	>
	<label>Rows <input type="number" bind:value={config.rows} step="1" min="1" max="9" /></label>
	<div class="end">
		<button on:click={saveConfig}>Start</button>
	</div>
</div>

<div class="grid" style:grid-template-columns={columns} style:grid-template-rows={rows}>
	{#each frames as frame, index}
		<FrameEditor bind:frame />
	{/each}
</div>

<style>
	* {
		color: white;
	}
	input {
		color: black;
	}
	.config {
		display: flex;
		flex-direction: row;
		padding: 1rem;
		background-color: #222;
		border-radius: 0.5rem;
		align-items: center;
	}
	.grid {
		display: grid;
		gap: 0;
		width: 100%;
		height: 100%;
		max-width: 100vw;
		min-height: 100vh;
	}
	.end {
		margin-left: auto;
	}

	.config button {
		color: black;
		padding: 0.5rem;
	}
</style>
