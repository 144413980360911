<script lang="ts">
	import { CRON_REGEX, CRON_REGEX_STRING } from "../helpers/cron.js";
	import { determineFrameType, generalizeFrame, switchFrameType } from "../helpers/frame-config.js";
	import { FrameType, type iFrame } from "../interfaces/iFrame.js";

	export let frame: iFrame;
	let chosen_type = determineFrameType(frame) ?? FrameType.URLFrame;
	// $: chosen_type = determineFrameType(frame) ?? FrameType.URLFrame;
	$: frame.rotation ??= 0;
	let use_cron = Boolean(frame.refresh_cron);
	$: if (!use_cron) {
		frame.refresh_cron = undefined;
	}

	let clientWidth, clientHeight;
</script>

<div
	class="frame"
	bind:clientWidth
	bind:clientHeight
	style:--max-width="{clientWidth}px"
	style:--max-height="{clientHeight}px"
>
	<fieldset
		class="frame-type"
		on:change={() => {
			generalizeFrame(frame);
			frame = switchFrameType(frame, chosen_type);
			// console.debug("change", frame, chosen_type);
		}}
	>
		<legend>Frame Type</legend>
		<label>
			<input type="radio" bind:group={chosen_type} value={FrameType.URLFrame} />
			<span>URL</span>
		</label>
		<label>
			<input type="radio" bind:group={chosen_type} value={FrameType.VideoFrame} />
			<span>Video</span>
		</label>
	</fieldset>
	<fieldset class="frame-content">
		<legend>Frame Content</legend>
		{#if "video_src" in frame}
			<label>Video URL <textarea bind:value={frame.video_src} /></label>
			<label>Color <input type="color" bind:value={frame.css_color} /></label>
		{:else if "url" in frame}
			<label>Page URL <textarea bind:value={frame.url} /></label>
			<label>Color <input type="color" bind:value={frame.css_color} /></label>
			<label class="oneline">
				<span>
					Check if page returns HTTP 200.
					<em>Requires CORS to be set permissively on the target server.</em>
				</span>
				<input type="checkbox" bind:checked={frame.check_200} />
			</label>
		{/if}
		<label>
			Rotation Clockwise <code>{frame.rotation ?? 0}deg</code>
			<input type="range" bind:value={frame.rotation} min="0" max="270" step="90" />
		</label>
		<label>
			Restart Cron Schedule
			<a
				href="https://crontab.guru/{use_cron && frame.refresh_cron
					? `#${frame.refresh_cron?.replace(/ /g, '_')}`
					: ''}"
				target="_blank"
				rel="noopener noreferrer">Cron Validator</a
			>
			<div class="oneline">
				<input type="checkbox" bind:checked={use_cron} />
				<input
					type="text"
					pattern={CRON_REGEX_STRING}
					disabled={!use_cron}
					bind:value={frame.refresh_cron}
				/>
			</div>
		</label>
	</fieldset>
</div>

<style>
	.frame {
		display: flex;
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
		border: 2px solid white;
		flex-direction: column;
		justify-content: center;
		/* align-items: center; */
		box-sizing: border-box;
	}
	.frame-content label {
		padding: 0.25rem;
		max-width: calc(100% - 4rem);
		display: block;
	}

	fieldset {
		max-width: calc(var(--max-width) - 2rem);
	}

	.frame-content input,
	.frame-content textarea {
		width: 40rem;
		max-width: min(100%, calc(var(--max-width) - 2rem));
		resize: vertical;
		display: block;
		font-family: monospace;
	}

	.oneline {
		width: 40rem;
		max-width: min(100%, calc(var(--max-width) - 2rem));
		display: flex;
		flex-flow: row nowrap;
	}

	.oneline > span {
		display: inline-block;
	}

	.oneline input[type="checkbox"] {
		width: min-content;
		display: inline-block;
		flex-shrink: 1;
	}
	.oneline input[type="text"] {
		display: inline-block;
		flex-grow: 1;
	}
</style>
