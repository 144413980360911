import { FrameType, type iFrame, type URLFrame, type VideoFrame } from "../interfaces/iFrame.js";

export function switchFrameType(frame: iFrame, new_type: FrameType) {
	switch (new_type) {
		case FrameType.URLFrame:
			(frame as URLFrame).url = "";
			break;
		case FrameType.VideoFrame:
			(frame as VideoFrame).video_src = "";
			break;
	}
	return frame;
}

export function generalizeFrame(frame: iFrame) {
	if ("video_src" in frame) {
		delete (frame as any).video_src;
	}
	if ("url" in frame) {
		delete (frame as any).url;
	}
}

export function determineFrameType(frame: iFrame) {
	if ("video_src" in frame) {
		return FrameType.VideoFrame;
	} else if ("url" in frame) {
		return FrameType.URLFrame;
	}
}
