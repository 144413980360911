<script lang="ts">
	import { createEventDispatcher, onMount } from "svelte";
	import type { URLFrame } from "../../interfaces/iFrame.js";

	const dispatch = createEventDispatcher();
	export let frame: URLFrame;
	let dom_frame: HTMLIFrameElement;
	export let block_interaction = false;
	export let sandbox = false;
	export let title = "Frame";
	export let width: number;
	export let height: number;
	export let loaded = false;
	export let page_load_wait = 1000 * 60 * 10;
	let page_load_timeout: ReturnType<typeof setTimeout> | undefined = undefined;

	$: if (frame.url) loaded = false;

	onMount(() => {
		page_load_timeout = setTimeout(() => {
			page_load_timeout = undefined;
			if (!loaded) {
				console.warn("Frame", title, "failed its page load timeout for url", frame.url);
				onError();
			}
		}, page_load_wait);
	});

	async function onLoad(event?: Event) {
		if (frame.check_200) {
			if (await getPageHTTPOk()) {
				loaded = true;
				clearTimeout(page_load_timeout);
				page_load_timeout = undefined;
			} else {
				loaded = false;
				// keep the page timeout around
			}
		} else {
			loaded = true;
			clearTimeout(page_load_timeout);
			page_load_timeout = undefined;
		}
	}

	async function getPageHTTPOk() {
		let url: URL;
		try {
			url = new URL(frame.url);
		} catch (error) {
			return false;
		}
		try {
			console.debug("get", String(url));
			const get = await fetch(url, {
				method: "GET",
				redirect: "follow",
			});
			// console.debug("get", get);
			if (get.ok) {
				// console.debug("return true");
				return true;
			} else {
				console.warn("get failed", get.status);
			}
		} catch (error) {
			console.warn(error);
		}

		return false;
	}

	function onError() {
		dispatch("reload");
	}
</script>

{#if sandbox}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<iframe
		class="frame"
		referrerpolicy="no-referrer"
		{title}
		src={frame.url?.toString()}
		style:background-color={frame.css_color}
		sandbox={"allow-scripts allow-forms allow-same-origin"}
		{width}
		{height}
		bind:this={dom_frame}
		on:click|preventDefault={() => false}
		on:load={onLoad}
		style:pointer-events={block_interaction ? "none" : "unset"}
	/>
{:else}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<iframe
		class="frame"
		referrerpolicy="no-referrer"
		{title}
		{width}
		{height}
		src={frame.url?.toString()}
		style:background-color={frame.css_color}
		bind:this={dom_frame}
		on:click|preventDefault={() => false}
		on:load={onLoad}
		style:pointer-events={block_interaction ? "none" : "unset"}
	/>
{/if}

<style>
	iframe {
		border: none;
		box-sizing: border-box;
	}
</style>
